import currency from "currency.js";
// make sure rate can convert to decimal, isn't negative
export function checkRate(rate) {
  if (isNaN(currency(rate).value) || currency(rate).value <= 0) {
    console.log(`invalid rate: ${rate}`);
  }
  return isNaN(currency(rate).value);
}

export function checkQuantity(qt) {
  if(isNaN(parseInt(qt)) || parseInt(qt) <= 0){
    return true;
  }
  return false;
}

// check to make sure the email given is a valid email
export function checkEmail(email) {
  return !String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
}

// returns a string containing any errors in the customer data
// returns null
export function getCustomerErrors(customer) {
  let errors = "";

  if (customer.name === "") {
    errors += "Customer name cannot be empty\n";
  }
  
  for (const email of customer.emails) {
    if (checkEmail(email)) {
      errors += `Invalid email : ${email}\n`;
    }
  }

  const rate = currency(customer.tax_rate).value
  if (isNaN(rate) || rate < 0){
    errors += `Invalid tax rate: ${rate}\n`
  }
 
  for (const rateName in customer.rates) {
    if (checkRate(customer.rates[rateName])) {
      errors += `Invalid rate : ${customer.rates[rateName]}\n`;
    }
  }

  if (customer.hasOwnProperty("created_by") && customer.created_by === "") {
    errors += "Must include employee name\n";
  }

  return errors;
}
