import React, { useState, useEffect, useContext } from "react";
import { API } from "aws-amplify";

//mui
import MUIDataTable from "mui-datatables";
import {MuiThemeProvider} from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Add as AddIcon, NoteAdd as SummaryIcon } from "@material-ui/icons";
import { getMuiTheme, columns, options } from "./theme";

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Loader from "../../components/Loader/Loader";

// context
import {userContext} from "../../context/User";

export default function Invoices(props) {
  const user = useContext(userContext);
  const classes = useStyles();

  // state
  const [invoices, setInvoices] = useState(null); // raw data
  const [invoicesTable, setInvoicesTable] = useState([]); // data for mui table

  // init
  useEffect(() => {

    if (!user) return; // wait for cognito
    let params = { queryStringParameters: { user_id: user.user_id } };

    // fetch invoices from ddb
    API.get('api', 'invoices', params).then(resp => {
      console.log(resp);
      setInvoices(resp);

      // build array for mui table
      const invoicesTable = resp.map(invoice => {
        return [
          invoice.date,
          invoice.customer_number,
          invoice.customer_name,
          invoice.items,
          invoice.paid
        ];
      });

      setInvoicesTable(invoicesTable);
    });
  }, [user]);

  const buttons = [
    {
      text: "Create Invoice",
      icon: <AddIcon className={classes.icon} />,
      func: () => props.history.push('/create_invoice')
    },
    {
      text: "Generate Summary",
      icon: <SummaryIcon className={classes.icon} />,
      func: () => props.history.push('/generate_summary')
    },
  ];

  return (
    <React.Fragment>
      <PageTitle
        title="Invoices"
        buttons={buttons}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {invoices === null ? <Loader isSmall={props.isSmall} /> : null}
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title="Pickup Invoices"
              data={invoicesTable}
              columns={columns}
              options={{
                ...options,
                onRowClick: (_, index) => {
                  // get invoice_id from data (based on index)
                  const invoice_id = invoices[index.dataIndex].invoice_id;
                  props.history.push('/invoice?invoice_id=' + invoice_id);
                }
              }}
            />
          </MuiThemeProvider>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
