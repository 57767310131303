/***************************************************/
// Customer.js
//
//	A form to display information for a single customer.
//	Can be set to edit/create/view modes
/***************************************************/
import React, { useEffect, useState, useContext } from "react";
import { userContext } from "../../context/User";

//mui
import {
  Grid,
  Paper,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  Button,
  TextField,
  FormControl,
  InputAdornment,
} from "@material-ui/core";
import { ArrowBackIos as BackIcon, Clear, Add } from "@material-ui/icons";

import { API } from "aws-amplify";

// error checking
import { checkRate, checkEmail } from "../../libs/Errors";

// styles
import useStyles from "./styles";

export default function Customer({
  mode = "view",
  customerData = {},
  onSubmit,
  ...props
}) {
  const classes = useStyles();
  const user = useContext(userContext);

  //state
  const [customer, setCustomer] = useState({
    name: "",
    city: "",
    address: "",
    tax_rate: null,
    rates: {},
    emails: [],
    notes: "",
    created_by: "",
    after_hours: false,
    saturday_pickup: false,
  });

  const [newEmail, setNewEmail] = useState("");
  const [newRateName, setNewRateName] = useState("");
  const [newRateValue, setNewRateValue] = useState("");

  // if mode or data changes, populate customer data if necessary
  useEffect(() => {
    if (mode !== "create" && customerData) {
      setCustomer(customerData);
    }
  }, [mode, customerData]);

  // for simple text field changes, won't work for object/array
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      [name]: value,
    }));
  };

  return props.cognito && (
    <Grid container className={classes.container}>
      <Paper classes={{ root: classes.paperRoot }}>
        <React.Fragment>
          <Typography variant="h3" gutterBottom>
            Account Info
          </Typography>

          {/*Display Customer Name*/}
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
              <span style={{ fontWeight: "600", paddingRight: "15px" }}>
                Name
              </span>
              {mode === "view" ? (
                customer.name
              ) : (
                  <TextField
                    style={{ width: "75%" }}
                    name={"name"}
                    value={customer.name}
                    onChange={handleChange}
                  />
                )}
            </Grid>

            {/*Display Customer City*/}
            <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
              <span style={{ fontWeight: "600", paddingRight: "15px" }}>
                City
              </span>
              {mode === "view" ? (
                customer.city
              ) : (
                  <TextField
                    name={"city"}
                    style={{ width: "55%" }}
                    value={customer.city}
                    onChange={handleChange}
                  />
                )}
            </Grid>

            {/*Display Customer Address*/}
            <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
              <span style={{ fontWeight: "600", paddingRight: "15px" }}>
                Address
              </span>
              {mode === "view" ? (
                customer.address
              ) : (
                  <TextField
                    name={"address"}
                    style={{ width: "50%" }}
                    value={customer.address}
                    onChange={handleChange}
                  />
                )}
            </Grid>

            {/*Display Customer Emails*/}
            <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
              <span style={{ fontWeight: "600" }}>Contact</span>
              {mode === "view" ? (
                customer.emails.map((item) => {
                  return (
                    <li style={{ listStyleType: "none" }} key={item}>
                      {item}
                    </li>
                  );
                })
              ) : (
                  // Create/Update mode
                  <FormControl
                    style={{ minWidth: "100%" }}
                    className={classes.formControl}
                  >
                    {customer.emails.map((email, index) => {
                      let helperText = "";
                      if (checkEmail(email)) {
                        helperText = "Invalid email";
                      }

                      return (
                        <Grid container>
                          {/*Delete Button*/}
                          <Grid item xs={1}>
                            <Button
                              style={{
                                maxWidth: "20px",
                                maxHeight: "20px",
                                minHeight: "20px",
                                minWidth: "20px",
                                marginTop: "5px",
                              }}
                              variant="outlined"
                              onClick={() => {
                                setCustomer((prevCust) => ({
                                  ...prevCust,
                                  emails: customer.emails.filter(
                                    (_, i) => i !== index,
                                  ),
                                }));
                              }}
                              startIcon={<Clear style={{ marginLeft: "10px" }} />}
                              size="small"
                            />
                          </Grid>

                          {/*Existing Customer Email*/}
                          <Grid item xs={10}>
                            <TextField
                              style={{
                                padding: "0 0 10px 10px",
                                minWidth: "70%",
                              }}
                              value={email}
                              error={checkEmail(email)}
                              helperText={helperText}
                              onChange={(e) => {
                                const newEmails = customer.emails.map(
                                  (email, i) => {
                                    if (i === index) {
                                      return e.target.value;
                                    } else {
                                      return email;
                                    }
                                  },
                                );
                                setCustomer({
                                  ...customer,
                                  emails: newEmails,
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    })}
                    {/*New Email Entry Form*/}
                    <Grid container>
                      {/*Add button*/}
                      <Grid item xs={1}>
                        <Button
                          style={{
                            maxWidth: "20px",
                            maxHeight: "20px",
                            minHeight: "20px",
                            minWidth: "20px",
                            marginTop: "5px",
                          }}
                          variant="outlined"
                          disabled={newEmail === ""}
                          onClick={() => {
                            const temp = {
                              ...customer,
                              emails: [...customer.emails, newEmail],
                            };
                            setNewEmail("");
                            setCustomer(temp);
                          }}
                          startIcon={<Add style={{ marginLeft: "10px" }} />}
                          size="small"
                        />
                      </Grid>

                      <Grid item xs={10}>
                        <TextField
                          value={newEmail}
                          onChange={(e) => {
                            setNewEmail(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                )}
            </Grid>

            {/*After Hours Availability*/}
            <Grid item xs={6} style={{ fontSize: "1.15rem" }}>
              <span style={{ fontWeight: "600", paddingRight: "15px" }}>
                After Hours:
              </span>
              {mode === "view" ? (
                customer.after_hours ? (
                  <span>{`\u2705 Available`}</span>
                ) : (
                    <span>{`\u274C Not Available`} </span>
                  )
              ) : (
                  <Checkbox
                    checked={Boolean(customer.after_hours)}
                    onChange={(e) => {
                      setCustomer((prevCust) => ({
                        ...prevCust,
                        after_hours: e.target.checked,
                      }));
                    }}
                  />
                )}
            </Grid>

            {/*Saturday Availability*/}
            <Grid item xs={6} style={{ fontSize: "1.15rem" }}>
              <span style={{ fontWeight: "600", paddingRight: "15px" }}>
                Sat. Pickup:
              </span>
              {mode === "view" ? (
                customer.saturday_pickup ? (
                  <span>{`\u2705 Available`}</span>
                ) : (
                    <span>{`\u274C Not Available`} </span>
                  )
              ) : (
                  <Checkbox
                    checked={Boolean(customer.saturday_pickup)}
                    onChange={(e) => {
                      setCustomer((prevCust) => ({
                        ...prevCust,
                        saturday_pickup: e.target.checked,
                      }));
                    }}
                  />
                )}
            </Grid>

            {/*Tax Rate*/}
            <Grid item xs={12}>
              <span style={{ fontSize: "1.15rem", fontWeight: "600", paddingRight: "20px" }}>
                Tax Rate:
                </span>
              {
                mode === "view" ?
                  <span>{customer.tax_rate}</span> :
                  <TextField
                    name={"tax_rate"}
                    value={customer.tax_rate}
                    style={{ width: "25px" }}
                    onChange={handleChange}
                  />
              }
            </Grid>
            {/*Display Current Rates*/}
            <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
              <FormControl
                style={{ minWidth: "100%" }}
                className={classes.formControl}
              >
                <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                  Rates
                </span>
                {
                  // View Mode
                  mode === "view"
                    ? Object.entries(customer.rates).map(([key, value]) => {
                      const formatted = new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(value);

                      return (
                        <li style={{ listStyleType: "none" }} key={key}>
                          {key + ": " + formatted}
                        </li>
                      );
                    })
                    : // Create/Edit Mode
                    Object.entries(customer.rates).map(([key, value]) => {
                      let helperText = "";
                      if (checkRate(value)) helperText = "Invalid rate";

                      return (
                        <Grid key={key} container>
                          <Grid item xs={1}>
                            <Button
                              style={{
                                maxWidth: "20px",
                                maxHeight: "20px",
                                minHeight: "20px",
                                minWidth: "20px",
                                marginTop: "5px",
                              }}
                              variant="outlined"
                              disabled={mode === "view"}
                              onClick={() => {
                                let temp = { ...customer.rates };
                                delete temp[key];
                                setCustomer((prevCustomer) => ({
                                  ...prevCustomer,
                                  rates: temp,
                                }));
                              }}
                              startIcon={
                                <Clear style={{ marginLeft: "10px" }} />
                              }
                              size="small"
                            />
                          </Grid>

                          <Grid item xs={7}>
                            <span>{key}</span>
                          </Grid>

                          <Grid item xs={2}>
                            <TextField
                              error={checkRate(value)}
                              helperText={helperText}
                              value={value}
                              onChange={(e) => {
                                let temp = { ...customer.rates };
                                temp[key] = e.target.value;
                                setCustomer((prevCustomer) => ({
                                  ...prevCustomer,
                                  rates: temp,
                                }));
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    })
                }
                {
                  // New Rate Field
                  mode === "view" ? null : (
                    <Grid container>
                      <Grid item xs={1}>
                        <Button
                          style={{
                            maxWidth: "20px",
                            maxHeight: "20px",
                            minHeight: "20px",
                            minWidth: "20px",
                            marginTop: "5px",
                          }}
                          variant="outlined"
                          disabled={newRateName === ""}
                          onClick={() => {
                            let temp = customer.rates;
                            temp[newRateName] = newRateValue;
                            setCustomer((prevCust) => ({
                              ...prevCust,
                              rates: temp,
                            }));
                            setNewRateName("");
                            setNewRateValue("");
                          }}
                          startIcon={<Add style={{ marginLeft: "10px" }} />}
                          size="small"
                        />
                      </Grid>

                      <Grid item xs={7}>
                        <TextField
                          value={newRateName}
                          placeholder="rate name"
                          onChange={(e) => {
                            setNewRateName(e.target.value);
                          }}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <TextField
                          value={newRateValue}
                          placeholder="rate"
                          style={{ whiteSpace: "pre-wrap" }}
                          startadornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                          onChange={(e) => {
                            setNewRateValue(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  )
                }
              </FormControl>
            </Grid>

            {/*Created By Employee*/}
            {mode === "create" ? (
              <Grid item xs={12}>
                <span
                  style={{
                    fontSize: "1.15rem",
                    fontWeight: "600",
                    paddingRight: "15px",
                  }}
                >
                  Salesperson:
                </span>
                <Select
                  value={customer.created_by}
                  style={{ width: "30%" }}
                  onChange={(e) => setCustomer((prevCust) => (
                    {
                      ...prevCust,
                      created_by: e.target.value,
                    }
                  ))}

                >
                  <MenuItem value={"Ty"}>Ty</MenuItem>
                  <MenuItem value={"Jakob"}>Jakob</MenuItem>
                  <MenuItem value={"Midanah"}>Midanah</MenuItem>
                  <MenuItem value={"Tessa"}>Tessa</MenuItem>
                </Select>
              </Grid>
            ) : (
                <></>
              )}

            {/*Note*/}
            <Grid item xs={12}>
              <span
                style={{
                  fontSize: "1.15rem",
                  fontWeight: "600",
                  paddingRight: "3px",
                }}
              >
                Notes
              </span>
              {mode === "view" ? (
                <div
                  style={{
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                    fontSize: "1.15rem",
                    float: "left",
                    width: "100%",
                    overflowY: "auto",
                  }}
                >
                  {customer.notes}
                </div>
              ) : (
                  <FormControl fullWidth>
                    <TextField
                      multiline
                      name="notes"
                      variant="outlined"
                      minRows={5}
                      style={{
                        wordWrap: "break-word",
                        fontSize: "1.15rem",
                        float: "left",
                        overflowY: "auto",
                        width: "100%",
                      }}
                      value={customer.notes}
                      onChange={handleChange}
                    />
                  </FormControl>
                )}
            </Grid>
            {
              customer.created_at && (
                <Grid item >
                  <span
                    style={{
                      fontSize: "1.15rem",
                      fontWeight: "600",
                      paddingRight: "3px",
                    }}
                  >
                    Account Creation Date:
									</span>
                  {Intl.DateTimeFormat('en-US', { year: "numeric", month: "long", day: "numeric" }).format(new Date(customer.created_at))}
                </Grid>
              )
            }
          </Grid>
          <div style={{ display: "flex", width: "100%" }}>
            <span
              style={{ marginTop: "19px", cursor: "pointer", color: "#FF7F01" }}
              onClick={() => props.history.goBack()}
            >
              <BackIcon />
              <span style={{ verticalAlign: "6px", fontSize: "1.1rem" }}>
                Back
              </span>
            </span>
            {
              props.cognito.role === "admin" ? (
                <>
                  <Button
                    style={{ backgroundColor: "red", marginLeft: "auto", marginTop: "10px" }}
                    variant="contained"
                    size="large"
                    className={classes.backButton}
                    onClick={(e) => {
                      e.stopPropagation(); // stop the onRowClick event

                      if (window.confirm(`Are you sure you would like to delete ${customer.name}`)) {

                        let params = {queryStringParameters: {user_id: user.user_id}};
                        API.del('api', 'customers_delete/' + customer.customer_id, params).then(_ => {
                          props.history.push("/customers");
                        })
                      }
                    }}
                  >
                    Delete
              </Button>
                  <Button
                    style={{ marginLeft: "auto", marginTop: "10px" }}
                    variant="contained"
                    color="secondary"
                    size="large"
                    className={classes.backButton}
                    onClick={() => {
                      onSubmit(customer);
                    }}
                  >
                    {mode === "view" ? "Update" : "Submit"}
                  </Button>
                </>
              ) :
                <></>
            }
          </div>
        </React.Fragment>
      </Paper>
    </Grid>
  );
}
