
import { createTheme } from "@material-ui/core/styles";

export const options = {
    filter: true,
    responsive: "vertical",
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 25, 50],
    filterType: "checkbox",
    selectableRows: "none",
  };

export const columns = [
  {
    label: "#",
    name: "#",
    options: {
      filter: false,
    },
  },
  {
    label: "Name",
    name: "Name",
    options: {
      filter: false,
    },
  },
  {
    label: "City",
    name: "City",
  },
  {
    label: "After Hours",
    name: "After Hours",
  },
  {
    label: "Rates",
    name: "Rates",
    options: {
      filter: false,
    },
  },
  {
    label: "Last Pickup",
    name: "Last Pickup",
    options: {
      filter: false,
    },
  },
];

export function getMuiTheme() {
  return createTheme({
    overrides: {
      MuiTableRow: {
        hover: {
          "&$root": {
            "&:hover": {
              cursor: "pointer", // make row's look clickable
              backgroundColor: "rgba(74, 74, 74, 0.08)", // force bg color
            },
          },
        },
      },
      MuiTableCell: {
        root: {
          padding: "15px 20px 15px 30px",
        },
      },
      MuiIconButton: {
        root: {
          color: "#116466 !important", // primary color
        },
      },
      MUIDataTableHeadCell: {
        // force column width
        root: {
          "&:nth-child(1)": {
            width: 100,
          },
          "&:nth-child(2)": {
            width: 250,
          },
          "&:nth-child(3)": {
            width: 175,
          },
          "&:nth-child(4)": {
            width: 100,
          },
          "&:nth-child(5)": {
            width: 100,
          },
        },
      },
    },
  });
}
