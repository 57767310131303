import React from "react";
import { useState, createContext, useContext, useEffect } from "react"
import { API } from "aws-amplify";
import { userContext } from "./User";

export const customersContext = createContext(null);



export default function CustomersProvider(props) {
  const [customers, setCustomers] = useState(null);
  const user = useContext(userContext);

  // when user data changes, load data
  useEffect(() => {
    if (!user) return;

    // get user table entry
    if (user.role === "admin" || user.role === "hauler") {
      API.get("api", "customers/").then(async (resp) => {
        setCustomers(resp);
      }).catch((err) => {
        console.log(`Error loading customers ${err}`);
      });
    }
  }
    , [user]);

  return (
    <customersContext.Provider value={customers}>
      {props.children}
    </customersContext.Provider>
  );

}

