import React, { useState, useEffect, useContext, useRef } from "react";

import { API } from "aws-amplify";
import { querystring } from "../../libs/querystring"

import {userContext} from "../../context/User";

// mui
import {
  Grid, Paper, Typography, Button, CircularProgress, 
  FormControl, Checkbox, FormControlLabel,
} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Clear, ArrowBackIos as BackIcon  } from "@material-ui/icons";

import SignaturePad from "react-signature-canvas"

// styles
import useStyles from "./styles";

import { checkRate, checkQuantity } from "../../libs/Errors";

export default function Create(props) {

  const classes = useStyles();
  const signatureCanvas = useRef({});
  const user = useContext(userContext);

  //state
  const [customerNum, setCustomerNum] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [customerNumErr, setCustomerNumErr] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [date, setDate] = useState(new Date(Date.now()));
  const [dateErr, setDateErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isPaid, setIsPaid] = useState(false);
  const [checkNum, setCheckNum] = useState("");
  const [items, setItems] = useState([])

  useEffect(() => {
    if (!user) return;

    setIsLoading(true);

    // get ticket id from url
    const ticket_id = querystring('ticket_id');
    let params = { queryStringParameters: { user_id: user.user_id } };

    // fetch ticket from ddb
    API.get('api', 'tickets/' + ticket_id, params).then(resp => {

      setItems(resp.items);
      setCustomerId(resp.customer_id);
      setCustomerNum(resp.customer_number);

      API.get('api', 'customers/' + resp.customer_number, params).then(custResp => {
        setCustomerName(custResp.name);
        setIsLoading(false);
      })
    }).catch(() => console.log('here')); // TODO: handle error
  }, [user]);

  function handleCreate() {
    // reset form errors
    setDateErr(false);
    setCustomerNumErr(false);

    // check store num
    if (!customerNum) {
      setCustomerNumErr(true);
      return;
    }

    // get date
    if (!date || date.toString() === 'Invalid Date') {
      setDateErr(true);
      return;
    }

    // create invoice
    API.post("api", "create_invoice", {
      body: {
        items: items,
        customer_number: customerNum.padStart(4, '0'),
        customer_id: customerId,
        customer_name: customerName,
        signature: signatureCanvas.current.getTrimmedCanvas().toDataURL('image/png'),
        paid: isPaid,
        check_number: isNaN(parseInt(checkNum)) ? 0 : parseInt(checkNum),
        date: date.toISOString(),
      }
    }).then(() => props.history.push('/invoices'));
  }

  return (
    <Grid container className={classes.container}>
      <Paper classes={{ root: classes.paperRoot }}>
        <React.Fragment>
          <Typography variant="h3" gutterBottom>
            Create Invoice for Pickup
          </Typography>
          <Grid container spacing={3}>

            <Grid item xs={4} sm={3}>
              <TextField
                required
                label="Acct#"
                disabled
                error={customerNumErr}
                value={customerNum}
                onChange={e => setCustomerNum(e.target.value)}
                helperText={customerNumErr ? "Invalid Store#" : null}
                inputProps={{ style: { fontSize: '1.15rem' } }} // font size of input text
                InputLabelProps={{ style: { fontSize: '1.15rem' } }} // font size of input label
              />
            </Grid>

            <Grid item xs={6} sm={5}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  error={dateErr}
                  disableToolbar
                  variant="inline"
                  format="MM-dd-yyyy"
                  label="Date"
                  inputProps={{ style: { fontSize: '1.15rem' } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: '1.15rem' } }} // font size of input label
                  value={date}
                  onChange={e => setDate(e)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  autoOk={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={6} sm={4}>
              <span />
            </Grid>

            <Grid container id={"current-items"} > {/*+ Current Items */}
              {
                items.map((item, index) => {
                  return (
                    <Grid item container> {/*+ Item row */}

                      <Grid item xs={1}> {/*+ Delete Button */}
                        <Button
                          style={{
                            maxWidth: "20px",
                            maxHeight: "20px",
                            minHeight: "20px",
                            minWidth: "20px",
                            marginTop: "10px"
                          }}
                          variant="outlined"
                          onClick={() => {
                            setItems(items.filter((_, i) => i !== index));
                          }}
                          startIcon={<Clear style={{ marginLeft: "10px" }} />}
                          size="small"
                        />
                      </Grid> {/*- Delete Button */}

                      <Grid item xs={3}>
                        <TextField
                          value={item.rate}
                          disabled
                          error={item.rate === ""}
                          onChange={(e) => {
                            setItems(items.map((item, i) => {
                              if (i === index) {
                                return { ...item, "rate": e.target.value };
                              }
                              return item;
                            }))
                          }}
                          style={{ marginRight: "20px", marginTop: "5px" }} />
                      </Grid>

                      <Grid item xs={2}>
                        <TextField
                          value={item.price}
                          disabled
                          error={checkRate(item.price) || item.price === ""}
                          onChange={(e) => {
                            setItems(items.map((item, i) => {
                              if (i === index) {
                                return { ...item, "price": e.target.value };
                              }
                              return item;
                            }))
                          }}
                          style={{ marginRight: "20px", marginTop: "5px" }} />
                      </Grid >

                      <Grid item xs={2}>
                        <TextField
                          value={item.quantity}
                          error={checkQuantity(item.quantity)}
                          type="number"
                          onChange={(e) => {
                            setItems(items.map((item, i) => {
                              return i === index ? (
                                { ...item, "quantity": e.target.value }
                              ) : item;
                            }))
                          }}
                          style={{ marginRight: "20px", marginTop: "5px" }} />
                      </Grid >

                      <Grid item xs={1}>
                        <FormControl style={{ marginBottom: "5px" }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled
                                checked={item.inside}
                                onChange={(e) => {
                                  setItems(items.map((item, i) => {
                                    return i === index ? (
                                      { ...item, "inside": e.target.checked }
                                    ) : item;
                                  }))
                                }}
                              />}
                            label="Inside" />
                        </FormControl>
                      </Grid>

                    </Grid>
                  )
                })
              }
            </Grid>
            <Grid item xs={12}>
              <FormControl style={{ marginBottom: "5px" }}>
                <FormControlLabel
                  style={{}}
                  control={
                    <Checkbox
                      checked={isPaid}
                      onChange={(e) => {
                        setIsPaid(e.target.checked);
                      }}
                    />}
                  label={<Typography style={{ fontSize: "1.20rem" }}>Mark as paid</Typography>} />
              </FormControl>
            </Grid>
            {
              isPaid && (
                <Grid item xs={12}>
                  <FormControl style={{ width: "50%", marginBottom: "5px" }}>
                    <FormControlLabel
                      style={{}}
                      labelPlacement="start"
                      control={
                        <TextField
                          value={checkNum}
                          fullWidth
                          helperText="Leave Blank for Cash"
                          onChange={(e) => {
                            setCheckNum(e.target.value);
                          }}
                        />}
                      label={<Typography style={{ marginRight: "20px", fontSize: "1.20rem" }}>Check#:</Typography>} />
                  </FormControl>
                </Grid>
              )
            }
            <div style={{ marginTop: "25px" }}>Signature:</div>
            <SignaturePad
              canvasProps={{
                style: {
                  width: "100%",
                  border: "1px solid black",
                  margin: "25px 0px 25px 0px"
                }
              }}
              ref={signatureCanvas}
            />
          </Grid>
        </React.Fragment>
        <div style={{ display: "flex", "width": "100%" }}>
          <span
            style={{ marginTop: "19px", cursor: "pointer", color: "#FF7F01" }}
            onClick={() => {
              if (isLoading) return;
              props.history.goBack();
            }}
          >
            <BackIcon />
            <span style={{ verticalAlign: "6px", fontSize: "1.1rem" }}>
              Back
            </span>
          </span>
          {isLoading ? (
            <CircularProgress size={26} style={{ marginLeft: "auto", marginTop: "20px" }} />
          ) : (
              <Button
                style={{ marginLeft: "auto", marginTop: "10px" }}
                variant="contained"
                color="secondary"
                size="large"
                className={classes.backButton}
                onClick={handleCreate}
              >
                Confirm
              </Button>
            )}
        </div>
      </Paper>
    </Grid>
  );
}
