import React, { useState, useEffect, useContext } from "react";
import Customer from "./Customer";
import { API } from "aws-amplify";
import currency from "currency.js";
import { getCustomerErrors } from "../../libs/Errors";

// components
import Loader from "../../components/Loader/Loader";

// libs
import { querystring } from "../../libs/querystring";

import {userContext} from "../../context/User";

export default function Update(props) {

  const user = useContext(userContext);

  //state
  const [customer, setCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  //init
  useEffect(() => {
    if (!user) return;

    const customer_id = querystring("customer_id");
    const params = { queryStringParameters : {user_id : user.user_id} };

    // get customer data and populate state
    setIsLoading(true);
    API.get("api", "customers/" + customer_id, params)
      .then((resp) => {
        setCustomer(resp);
        setIsLoading(false);
      })
      .catch((err) => console.log(`Error Loading Customer (${customer_id}) : ${err}`)); // TODO: handle error
  }, [user]);

  // Called when the submit button is pressed.
  function handleUpdate(newCustomer) {

    // create a message containing any errors in the customer data
    let errorString = getCustomerErrors(newCustomer);

    if (errorString) {
      alert(errorString);
      return;
    }

    // convert all the rates to decimal values
    Object.keys(newCustomer.rates).forEach((key) => {
      newCustomer.rates[key] = currency(newCustomer.rates[key]).value;
    })

    // update customer
    API.post("api", "update_customer/" + customer.customer_id, {
      body: newCustomer,
    }).then(() => props.history.push("/customers"));
  }

  return customer && !isLoading ? (
    <Customer
      mode="edit"
      customerData={customer}
      onSubmit={handleUpdate}
      {...props}
    />
  ) : (
      <Loader isSmall={props.isSmall} />
    );
}
