import React, { useEffect, useState, useContext } from "react";
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import { API } from "aws-amplify";
import { ArrowBackIos as BackIcon } from "@material-ui/icons";
import moment from 'moment';

// libs
import { querystring } from "../../libs/querystring"

// components
import Loader from "../../components/Loader/Loader";
import { Button as Badge } from "../../components/Wrappers/Wrappers";

// styles
import useStyles from "./styles";

import { userContext } from "../../context/User";

const roles = {
  'customer': 'Account',
  'admin': 'Admin',
  'hauler': 'Hauler'
}

export default function Ticket(props) {
  const classes = useStyles();
  const user = useContext(userContext);

  //state
  const [ticket, setTicket] = useState(null);
  const [showCustomer, setShowCustomer] = useState(false);
  const [customer, setCustomer] = useState(null);

  // badge colors
  const states = {
    Open: "secondary",
    Pending: "info",
    Closed: "primary"
  };

  useEffect(() => {
    // get ticket id from url
    const ticket_id = querystring('ticket_id');
    // fetch ticket from ddb
    API.get('api', 'tickets/' + ticket_id).then(resp => {
      setTicket(resp);
    }).catch(() => console.log('here')); // TODO: handle error
  }, []);

  useEffect(() => {
    if (!showCustomer || !user) return;

    const params = { queryStringParameters: { user_id: user.user_id } }

    API.get('api', 'customers/' + ticket.customer_id, params).then(resp => {
      setCustomer(resp);
    }).catch(() => console.log('here')); // TODO: handle error

  }, [showCustomer, ticket, user]);

  let color;
  if (ticket) {
    if (ticket.urgency === 'Urgent') {
      if (ticket.status === 'Open')
        color = 'warning';
      else if (ticket.status === 'Pending')
        color = 'success';
      else
        color = 'primary';
    } else {
      color = states[ticket.status];
    }
  }

  return (
    ticket ? (
      <Grid container className={classes.container}>
        <Paper classes={{ root: classes.paperRoot }}>
          <React.Fragment>
            <Typography variant="h3" gutterBottom>
              Pickup Request
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                <div>
                  <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                    Acct#
                  </span> {ticket.customer_number}
                  {!showCustomer && (
                    <span
                      style={{ cursor: 'pointer', fontSize: "1.0rem", fontWeight: "600", paddingLeft: "8px", color: "#FF7F01" }}
                      onClick={() => setShowCustomer(true)}
                    >
                      Show Store Info
                    </span>
                  )
                  }
                </div>
                {customer && (
                  <React.Fragment>
                    <div style={{ paddingLeft: "10px" }}>
                      <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                        Name
                      </span> {customer.name}
                    </div>
                    <div style={{ paddingLeft: "10px" }}>
                      <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                        City
                      </span> {customer.city}
                    </div>
                    <div style={{ paddingLeft: "10px" }}>
                      <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                        Address
                      </span> {customer.address}
                    </div>
                    {
                      customer.emails && (
                        <div style={{ paddingLeft: "10px" }}>
                          <span style={{ fontWeight: "600", paddingRight: "15px" }}>
                            Contact
                        </span>
                          <div style={{ display: "inline-flex", width: "50%" }}>
                            <div style={{ listStyleType: "none" }}>
                              {
                                customer.emails.map((email) => (
                                  <li>{email}</li>
                                ))
                              }
                            </div>
                          </div>
                        </div>)
                    }
                  </React.Fragment>
                )
                }
              </Grid>

              <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                  Date
                </span> {moment(ticket.date).format('MM-DD-YYYY h:mma')}
              </Grid>

              <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                  Items
                </span>
              </Grid>

              <Grid item container xs={12}>

                <Grid item container xs={12}>
                  <Grid item xs={3} style={{ fontWeight: "600" }}>Rate Type</Grid>
                  <Grid item xs={3} style={{ fontWeight: "600" }}>Price</Grid>
                  <Grid item xs={3} style={{ fontWeight: "600" }}>Quantity</Grid>
                  <Grid item xs={3} style={{ fontWeight: "600" }}>Inside</Grid>
                </Grid>

                {
                  ticket.items.map((item) => (
                    <Grid item container xs={12}>
                      <Grid item xs={3}>{item.rate}</Grid>
                      <Grid item xs={3}>{item.price}</Grid>
                      <Grid item xs={3}>{item.quantity}</Grid>
                      <Grid item xs={3}>{item.inside ? '\u2705' : '\u274c'}</Grid>
                    </Grid>
                  ))
                }

              </Grid>

              <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                <span style={{ fontWeight: "600", paddingRight: "6px", verticalAlign: '-1px' }}>
                  Status
                </span>
                <Badge
                  color={color}
                  size="small"
                  className="px-2"
                  variant="contained"
                >
                  {ticket.urgency === 'Urgent' ? ticket.status + " (Urgent)" : ticket.status}
                </Badge>
              </Grid>

              <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                  ETA
                </span> {ticket.eta ? moment(ticket.eta).format('MM-DD-YYYY') : 'unknown'}
              </Grid>
              <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                  Notes
                </span>
                {
                  ticket.notes.map((note, idx) => (
                    <div key={idx} style={{ wordBreak: "break-word", padding: '0px 0px 5px 10px' }}>
                      <span style={{ fontWeight: "600" }}>
                        {moment(Object.keys(note)[0].split('Z')[0] + 'Z').format('MM-DD-YY h:mma')}
                        {Object.keys(note)[0].split('Z')[1] && roles[Object.keys(note)[0].split('Z')[1]] ?
                          <>&nbsp;({roles[Object.keys(note)[0].split('Z')[1]]})&nbsp;</> :
                          <>&nbsp;</>}
                      </span>
                      {Object.values(note)[0]}
                    </div>
                  ))
                }
              </Grid>
            </Grid>

            {((props.cognito) || ticket.status !== 'Closed') && <Button
              style={{ marginLeft: "auto", width: "100%", margin: "20px 0px 20px 0px" }}
              variant="contained"
              color="primary"
              size="large"
              className={classes.backButton}
              onClick={() => props.history.push('/create_invoice?ticket_id=' + ticket.ticket_id)}
            >
              Create Invoice
            </Button>}
          </React.Fragment>
          <div style={{ display: "flex", "width": "100%" }}>
            <span
              style={{ marginTop: "19px", cursor: "pointer", color: "#FF7F01" }}
              onClick={() => props.history.goBack()}
            >
              <BackIcon />
              <span style={{ verticalAlign: "6px", fontSize: "1.1rem" }}>
                Back
              </span>
            </span>
            {((props.cognito && props.cognito.role === 'admin') || ticket.status !== 'Closed') && <Button
              style={{ marginLeft: "auto", marginTop: "10px", marginRight: "10px" }}
              variant="contained"
              color="secondary"
              size="large"
              className={classes.backButton}
              onClick={() => {
                if (window.confirm(`Are you sure you would like to delete this ticket?`)) {
                  API.del('api', 'delete_ticket/' + ticket.ticket_id).then(_ => {
                    props.history.push("/tickets");
                  })
                }
              }}
            >
              Delete
            </Button>}
            {((props.cognito && props.cognito.role === 'admin') || ticket.status !== 'Closed') && <Button
              style={{ marginLeft: "auto", marginTop: "10px", marginRight: "10px" }}
              variant="contained"
              color="secondary"
              size="large"
              className={classes.backButton}
              onClick={() => props.history.push('/update_ticket?ticket_id=' + ticket.ticket_id)}
            >
              Update
            </Button>}
          </div>
        </Paper>
      </Grid>
    ) : (<Loader isSmall={props.isSmall} />)
  );
}
