import React, { useEffect, useContext } from "react";
import Customer from "./Customer";
import { getCustomerErrors } from "../../libs/Errors";
import { userContext } from "../../context/User";
import { API } from "aws-amplify";
import currency from "currency.js"

export default function Create(props) {
  const user = useContext(userContext);
  //init
  useEffect(() => { }, []);

  const handleCreate = (newCustomer) => {
    // check customer data for errors, and get a string that
    // describes them
    let errorString = getCustomerErrors(newCustomer);

    if (errorString) {
      alert(errorString);
      return;
    }

    // convert rate prices to decimals
    Object.keys(newCustomer.rates).forEach((key) => {
      newCustomer.rates[key] = currency(newCustomer.rates[key]).value;
    })

    // convert tax rate to decimal
    newCustomer.tax_rate = currency(newCustomer.tax_rate).value

    // create the new customer object
    API.post("api", "create_customer/", {
      body: newCustomer,
      queryStringParameters: { user_id: user.user_id }
    }).then(() => props.history.push("/customers"));
  };

  return <Customer mode="create" onSubmit={handleCreate} {...props} />;
}
