import React from "react";
import { useState, createContext, useEffect } from "react"
import { API } from "aws-amplify";

export const userContext = createContext(null);


export default function UserProvider(props) {
  const [user, setUser] = useState(null);

  // when cognito data changes, load 
  useEffect(() => {
    if (!props.cognito) return;

    // get user table entry
    API.get("api", "users/" + props.cognito.UID)
      .then(async (userResp) => {

        if (!userResp)
          return;

        let userInfo = {};
        userInfo = userResp;
        userInfo.account_info = {};

				const params = { queryStringParameters : { user_id : userInfo.user_id } };
        // get all accounts associated with this user
        const accountPromises = userInfo.accounts.map(async (acct) => {
          const acctInfo = await API.get("api", "customers/" + acct, params);
          userInfo.account_info[acct] = acctInfo;
        });

        await Promise.all(accountPromises);
        setUser(userInfo);
      }).catch((err) => {
        console.log(`Error creating user context: ${err}`);
      })
  }
    , [props.cognito]);

  return (
    <userContext.Provider value={user}>
      {props.children}
    </userContext.Provider>
  );

}

