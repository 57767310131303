import React, { useState, useEffect } from "react";
import {
	Grid,
	CircularProgress,
	Typography,
	Button,
	Tabs,
	Tab,
	TextField,
	Fade,
	FormControl,
	InputLabel,
	MenuItem,
	Select
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Auth, API } from "aws-amplify";

// styles
import useStyles from "./styles";

// logo
import logo from "../../assets/logo.svg";

function Login(props) {
	var classes = useStyles();

	// local
	var [isLoading, setIsLoading] = useState(false);
	var [error, setError] = useState("");
	var [activeTabId, setActiveTabId] = useState(0);
	var [email, setEmail] = useState("");
	var [passwordValue, setPasswordValue] = useState("");
	var [phone, setPhone] = useState("");
	var [phoneErr, setPhoneErr] = useState(false);
	var [role, setRole] = useState("");
	var [customerNum, setCustomerNum] = useState("");
	var [customerNumErr, setCustomerNumErr] = useState(false);

	// when you switch tabs clear any active errors
	useEffect(() => {
		setCustomerNumErr(false);
		setError("");
		setPhoneErr(false);
	}, [activeTabId])


	return (
		<Grid container className={classes.container}>

			<div className={classes.logotypeContainer}>
				<img src={logo} alt="logo" className={classes.logotypeImage} />
				<Typography className={classes.logotypeText}>FacilityChex</Typography>
			</div>

			<div className={classes.formContainer}>
				<div className={classes.form}>

					<Tabs
						value={activeTabId}
						onChange={(_, id) => setActiveTabId(id)}
						indicatorColor="primary"
						textColor="primary"
						centered
					>
						<Tab label="Login" classes={{ root: classes.tab }} />
						<Tab label="New User" classes={{ root: classes.tab }} />
					</Tabs>

					{activeTabId === 0 && (
						<React.Fragment>
							<Fade in={error ? true : false}>
								<Typography color="secondary" className={classes.errorMessage}>
									{error}
								</Typography>
							</Fade>

							<form>
								<TextField
									id="email"
									InputProps={{
										classes: {
											underline: classes.textFieldUnderline,
											input: classes.textField,
										},
									}}
									value={email}
									onChange={e => setEmail(e.target.value)}
									margin="normal"
									placeholder="Email"
									type="email"
									fullWidth
								/>

								<TextField
									id="password"
									InputProps={{
										classes: {
											underline: classes.textFieldUnderline,
											input: classes.textField,
										},
									}}
									value={passwordValue}
									onChange={e => setPasswordValue(e.target.value)}
									margin="normal"
									placeholder="Password"
									type="password"
									fullWidth
								/>

								<div className={classes.formButtons}>
									{isLoading ? (
										<CircularProgress size={26} className={classes.loginLoader} />
									) : (
											<Button
												disabled={
													email.length === 0 || passwordValue.length === 0
												}
												onClick={async () => {
													setIsLoading(true);
													try {
														await Auth.signIn(email, passwordValue);
														props.userHasAuthenticated(true);
													} catch (e) {
														setError(e.message);
														setIsLoading(false);
													}
												}}
												variant="contained"
												color="primary"
												size="large"
												type='submit'
											>
												Login
											</Button>
										)}

									<Button
										color="primary"
										size="large"
										className={classes.forgetButton}
										onClick={() => props.history.push('/forgot')}
									>
										Forgot Password
                </Button>

								</div>
							</form>
						</React.Fragment>
					)}

					{activeTabId === 1 && (
						<React.Fragment>

							<Fade in={error ? true : false}>
								<Typography color="secondary" className={classes.errorMessage}>
									{error}
								</Typography>
							</Fade>

							<form>
								<Grid container spacing={3}>

									<Grid item xs={4} style={{ fontSize: "1.15rem" }}>
										<FormControl style={{ minWidth: 100 }} className={classes.formControl}>
											<InputLabel>Role</InputLabel>
											<Select
												value={role}
												onChange={e => setRole(e.target.value)}
											>
												<MenuItem disabled value={'admin'}>Admin</MenuItem>
												<MenuItem value={'customer'}>Customer</MenuItem>
												<MenuItem value={'hauler'}>Hauler</MenuItem>
											</Select>
										</FormControl>
									</Grid>

									{role === 'customer' &&
										<Grid item xs={4} style={{ fontSize: "1.15rem" }}>
											<TextField
												label="Store#"
												error={customerNumErr}
												InputProps={{
													classes: {
														underline: classes.textFieldUnderline,
														input: classes.textField,
													},
												}}
												value={customerNum}
												onChange={e => setCustomerNum(e.target.value)}
												fullWidth
											/>
										</Grid>

									}
								</Grid>

								<TextField
									id="email"
									InputProps={{
										classes: {
											underline: classes.textFieldUnderline,
											input: classes.textField,
										},
									}}
									value={email}
									onChange={e => setEmail(e.target.value)}
									margin="normal"
									placeholder="Email"
									type="email"
									fullWidth
								/>
								<TextField
									id="password"
									InputProps={{
										classes: {
											underline: classes.textFieldUnderline,
											input: classes.textField,
										},
									}}
									value={passwordValue}
									onChange={e => setPasswordValue(e.target.value)}
									margin="normal"
									placeholder="Password"
									type="password"
									fullWidth
								/>
								{role === 'hauler' &&
									<TextField
										error={phoneErr}
										InputProps={{
											classes: {
												underline: classes.textFieldUnderline,
												input: classes.textField,
											},
										}}
										value={phone}
										onChange={e => setPhone(e.target.value)}
										margin="normal"
										placeholder="Mobile phone #"
										fullWidth
									/>
								}
								<div className={classes.creatingButtonContainer}>
									{isLoading ? (
										<CircularProgress size={26} />
									) : (
											<Button
												onClick={async (e) => {
													e.preventDefault(); // prevent reload

													setError("");
													setCustomerNumErr(false);
													setPhoneErr(false);
													setIsLoading(true);

													try {
														if (role === 'hauler') {
															if (phone.replace(/\D+/g, '').length !== 10) {
																setPhoneErr(true);
																throw new Error("Invalid phone number.");
															}
														}

														const data = {
															username: email,
															password: passwordValue,
															attributes: {
																"custom:custom:role": role
															}
														};
														if (role === 'customer' && customerNum) {
															const resp = await API.get("api", `/validate_email?email=${email}&customer_number=${customerNum}`)
															if (resp.value) {
																console.log(resp)
																data.attributes["custom:custom:role_details"] = resp.customer_id;
															} else {
																setCustomerNumErr(true);
																throw new Error(resp.message)
															}
														}
														if (role === 'hauler' && phone)
															data.attributes["custom:phone"] = phone.replace(/\D+/g, '');

														await Auth.signUp(data);
														await Auth.signIn(email, passwordValue);
														props.userHasAuthenticated(true);

													} catch (e) {
														setError(e.message);
														setIsLoading(false);
													}
												}}
												disabled={
													email.length === 0 ||
													passwordValue.length === 0 ||
													role === '' ||
													(role === 'customer' && customerNum === '') ||
													(role === 'hauler' && phone === '')
												}
												size="large"
												variant="contained"
												color="primary"
												fullWidth
												className={classes.createAccountButton}
												type='submit'
											>
												Create account
											</Button>
										)}
								</div>
							</form>
						</React.Fragment>
					)}
				</div>
				<Typography color="primary" className={classes.copyright}>
					© 2020 FacilityChex. All rights reserved.
        </Typography>
			</div>
		</Grid>
	);
}

export default withRouter(Login);
