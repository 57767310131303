import React, { useState, useEffect, useContext } from "react";
import Customer from "./Customer";
import { userContext } from "../../context/User";
import { API } from "aws-amplify";

// components
import Loader from "../../components/Loader/Loader";

// libs
import { querystring } from "../../libs/querystring";

export default function Update(props) {
  const user = useContext(userContext);

  //state
  const [customer, setCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  //init
  useEffect(() => {
    if (!user) return;

    setIsLoading(true);

    const customer_id = querystring("customer_id")
    const params = { queryStringParameters: { user_id: user.user_id } }

    API.get("api", "customers/" + customer_id, params)
      .then((resp) => {
        setIsLoading(false);
        setCustomer(resp);
      })
      .catch((error) => console.log("errored:", error)); // TODO: handle error
  }, [user]);

  function handleGoToUpdate(customer) {
    // Go to update page
    props.history.push("/update_customer?customer_id=" + customer.customer_id);
  }

  return customer && !isLoading && Object.keys(customer).length > 0 ? (
    <Customer
      mode="view"
      customerData={customer}
      onSubmit={handleGoToUpdate}
      {...props}
    />
  ) : (
      <Loader isSmall={props.isSmall} />
    );
}
